// Responsive
@mixin bp-768 {
    @media all and (min-width: 768px) {
        @content;
    }
}
@mixin bp-992 {
    @media all and (min-width: 992px) {
        @content;
    }
}
@mixin bp-1200 {
    @media all and (min-width: 1200px) {
        @content;
    }
}
@mixin bp-1600 {
    @media all and (min-width: 1600px) {
        @content;
    }
}
@mixin bp-2000 {
    @media all and (min-width: 2000px) {
        @content;
    }
}

// Transition
@mixin transition($args...) {
    -webkit-transition: $args;
       -moz-transition: $args;
        -ms-transition: $args;
         -o-transition: $args;
            transition: $args;
}

// Background-size
@mixin background-size($bg-size) {
    -webkit-background-size: $bg-size;
       -moz-background-size: $bg-size;
         -o-background-size: $bg-size;
            background-size: $bg-size;
}

// Transform
@mixin transform($value) {
    -webkit-transform: $value;
        -ms-transform: $value;
         -o-transform: $value;
            transform: $value;
}

// Border-radius
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
}

// Animation
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}
@mixin animation($str) {
    -webkit-animation: #{$str};
       -moz-animation: #{$str};
        -ms-animation: #{$str};
         -o-animation: #{$str};
            animation: #{$str};      
}

// Box-shadow
@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
       -moz-box-shadow: $shadow;
            box-shadow: $shadow;
}