@import 'bootstrap.css'; // https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css
@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,400italic,700,700italic|Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic);
$primarycolor: #5c9df5;
$headingcolor: #2e3037;
$paragraphcolor: #444;
html {
  position: relative;
  min-height: 100%;
}
html.ie-11 {
	#content {
		margin-bottom: 0 !important;
	}
	#footer {
		position: relative !important;
		bottom: auto !important;
		float: left !important;
	}
}
body {
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;
	padding-top: 50px;
	background-color: #f0f2f6;
	@include bp-768 {
		padding-top: 55px;
	}
	@include bp-992 {
		padding-top: 59px;
	}
	@include bp-1200 {
		padding-top: 60px;
	}
}
#content {
	width: 100%;
	min-height: 100%;
	position: relative;
	float: left;
	margin-bottom: 365px;
	@include bp-768  {
		margin-bottom: 300px;
	}
	@include bp-992  {
		margin-bottom: 305px;
	}
	@include bp-1200  {
		margin-bottom: 325px;
	}
	h1,h2,h3,h4,h5,h6,input[type=submit] {
		font-family: 'PT Sans', sans-serif;
	}
	p,a,li,input,textarea {
		font-family: 'Open Sans', sans-serif;
	}
	a {
		outline: none
	}
	p > a {
		color: $primarycolor;
		&:hover {
			color: #2c80f2;
			text-decoration: underline;
		}
		&:focus,
		&:active {
			color: #1472f1;
		}
	}
	h1,h2,h3,h4,h5,h6,p,a,ul,li {
		padding: 0;
		margin: 0;
	}
	ul,li {
		list-style: none;
	}
	.section {
		//float: left;
		width: 100%;
		position: relative;
	}
	.btn-chimney {
		background-color: $primarycolor;
		color: #fff;
		font-size: 13px;
		//height: 38px;
		line-height: 37px;
		border: 0;
		font-weight: 700;
		position: relative;
		padding: 2px 20px 0 20px;
		font-family: 'PT Sans', sans-serif;
		@include transition(background-color .3s, box-shadow .3s);
		&:hover {
			background-color: #2c80f2;
		}
		&:focus,
		&:active {
			background-color: #1472f1;
		}
		&:active {
			@include transform(scale(.95));
		}
		@include bp-768 {
			font-size: 14px;
			padding: 1px 22px 0 22px;
			line-height: 41px;
			//height: 42px;
		}
		@include bp-1200 {
			padding: 3px 24px 0 24px;
			line-height: 43px;
			font-size: 15px;
			//height: 42px;
		}
  }
  .btn-chimney-ghost {
		background-color: transparent;
		color: #fff;
		font-size: 13px;
		//height: 38px;
    line-height: 33px;
    margin-left: 10px;
		border: 2px solid $primarycolor;
		font-weight: 700;
		position: relative;
		padding: 2px 20px 0 20px;
		font-family: 'PT Sans', sans-serif;
		@include transition(background-color .3s, box-shadow .3s, border-color .3s);
		&:hover {
      background-color: #2c80f2;
      border-color: #2c80f2;
		}
		&:focus,
		&:active {
			background-color: #1472f1;
      border-color: #1472f1;
		}
		&:active {
			@include transform(scale(.95));
		}
		@include bp-768 {
			font-size: 14px;
			padding: 1px 22px 0 22px;
			line-height: 37px;
			//height: 42px;
		}
		@include bp-1200 {
			padding: 3px 24px 0 24px;
			line-height: 39px;
			font-size: 15px;
			//height: 42px;
		}
	}
	.white-bg {
		background-color: #fff;
		// box-shadow: 0 1px 21px rgba(0,0,0,0.11);
		// border: 1px solid rgba(0,0,0,0.11);
		box-shadow: 0 1px 3px rgba(0,0,0,0.11);
		margin-top: 15px;
		width: 100%;
		float: left;
		@include bp-768 {
			margin-top: 30px;
		}
		&.right {
			padding: 15px;
			@include bp-768 {
				padding: 30px;
			}
		}
		h1,
		h2 {
			width: 100%;
			font-weight: 700;
			font-size: 20px;
			color: $headingcolor;
			@include bp-992 {
				font-size: 23px;
			}
			@include bp-1200 {
				// font-size: 24px;
			}
		}
		p {
			color: $paragraphcolor;
			font-size: 13px;
			width: 100%;
			max-width: 400px;
			margin-top: 5px;
			@include bp-768 {
				max-width: 600px;
				margin-top: 10px;
				font-size: 14px;
			}
			@include bp-1200 {
				font-size: 15px;
			}
		}
	}
	#header {
		.js-menu-overlay {
			position: fixed;
			background: rgba(0,0,0,.5);
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			display: none;
			@include bp-768 {
				display: none !important;
			}
		}
		background-color: rgba(29,31,35,1);
		//background-color: rgb(46, 48, 55);
		color: #fff;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 50px;
		z-index: 1337;
		.no-padding {
			padding: 0;
		}
		.no-margin {
			margin: 0;
		}
		@include bp-768 {
			height: 55px;
			.no-padding {
				padding: 0 15px;
			}
			.no-margin {
				margin: 0 -15px;
			}
		}
		@include bp-992 {
			height: 59px;
		}
		@include bp-1200 {
			height: 60px;
		}
		a.logo {
			float: left;
			display: block;
			// margin-top: 17px;
			padding: 12px 0 12px 15px;
			//height: 50px;
			@include bp-768 {
				padding: 14px 0;
				height: auto;
				margin-top: 1px;
			}
			@include bp-992 {
				padding: 15px 0;
				margin-top: 0;
			}
			img {
				width: 200px;
				margin-top: 1px;
				height: 25px;
				@include bp-768 {
					margin-top: 1px;
				}
				@include bp-992 {
					width: 216px;
					margin-top: 2px;
					height: 27px;
				}
				@include bp-1200 {
					width: 232px;
					margin-top: 1px;
					height: 29px;
				}
			}
		}
		span.menu-button {
			position: relative;
			float: right;
			height: 50px;
			width: 50px;
			background: transparent;
			&.click {
				background: #333538;
			}
			span {
				position: absolute;
				left: 15px;
				right: 15px;
				height: 2px;
				background: #fff;
				opacity: 1;
				@include border-radius(2px);
				@include transition(all .3s);
				&:nth-child(1) {
					top: 18px;
				}
				&:nth-child(2) {
					top: 24px;
				}
				&:nth-child(3) {
					bottom: 18px;
				}
			}
			&.click {
				span {
					&:nth-child(1) {
						top: 24px;
						@include transform(rotate(135deg));
					}
					&:nth-child(2) {
						opacity: 0;
					}
					&:nth-child(3) {
						bottom: 24px;
						@include transform(rotate(-135deg));
					}
				}
			}
		}
		ul {
			float: left;
			clear: left;
			width: 100%;
			background-color: #333538;
			display: none;
			@include bp-768 {
				display: block;
				width: auto;
				clear: none;
				float: right;
				background-color: transparent;
			}
			li {
				float: left;
				width: 100%;
				position: relative;
				//border-bottom: 1px solid rgba(29,31,35,1);
				@include bp-768 {
					width: auto;
					border-bottom: 0px solid transparent;
				}
				a {
					color: #fff;
					font-family: 'PT Sans', sans-serif;
					font-weight: 700;
					font-size: 14px;
					padding: 13px 15px;
					z-index: 10;
					float: left;
					//opacity: .5;
					color: #878fa2;
					width: 100%;
					position: relative;
					//color: #B0B8CA;
					@include transition(color .3s);
					@include bp-768 {
						padding: 0 10px;
						line-height: 55px;
						font-weight: 700;
						font-size: 13px;
					}
					@include bp-992 {
						padding: 0 15px;
						line-height: 59px;
						font-size: 14px;
					}
					@include bp-1200 {
						line-height: 60px;
						font-size: 15px;
					}
					&:hover,
					&.active {
						color: #fff;
						text-decoration: none;
						opacity: 1;
						&.js-menuparent span.arrow {
							background-color: #fff;
						}
					}
					&:active,
					&:focus {
						text-decoration: none;
						opacity: 1;
					}
					&.js-menuparent {
						background: transparent;
						@include transition(background .3s, color .3s);
						@include bp-768 {
							padding-right: 31px;
						}
						@include bp-1200 {
							padding-right: 32px;
						}
						span.arrow {
							position: absolute;
							background-color: #878fa2;
							height: 2px;
							top: 50%;
							width: 10px;
							margin-top: -1px;
							@include border-radius(2px);
							@include transition(all .3s);
							&:nth-child(1) {
								@include transform(rotate(45deg));
								right: 21px;
							}
							&:nth-child(2) {
								@include transform(rotate(-45deg));
								right: 15px;
							}
							@include bp-768 {
								width: 8px;
								&:nth-child(1) {
									right: -13px;
								}
								&:nth-child(2) {
									right: -18px;
								}
							}
							@include bp-1200 {
								&:nth-child(1) {
									right: -14px;
								}
								&:nth-child(2) {
									right: -19px;
								}
							}
						}
						&.active {
							span {
								.arrow {
									background-color: #fff;
								}
							}
						}
					}
					&.js-menuparent.click {
						color: #fff;
						background: rgba(29,31,35,1);
						span.arrow {
							background-color: #fff;
							&:nth-child(1) {
								@include transform(rotate(-45deg));
							}
							&:nth-child(2) {
								@include transform(rotate(45deg));
							}
						}
						@include bp-768 {
							background: #333538;
						}
						~ul {
							background: rgba(29,31,35,1);
							@include bp-768 {
								background: #333538;
							}
						}
					}
					span.text {
						top: 1px;
						@include bp-768 {
							position: relative;
							top: 1px;
							width: 100%;
							//display: block;
						}
						@include bp-992 {
							top: 0;
						}
						@include bp-1200 {
							top: 1px;
						}
					}
				}
				ul {
					padding-bottom: 7px;
					display: none;
					@include transition(background .3s);
					@include bp-768 {
						@include transition(background 0s);
						display: none;
						background: #333538;
						position: absolute;
						top: 100%;
						left: 0;
						padding: 7px 0;
						box-shadow: 0 1px 3px rgba(0,0,0,.75);
					}
					li {
						border: none;
						@include bp-768 {
							width: 100%;
						}
						a {
							padding: 7px 15px 7px 30px;
							font-weight: 400;
							@include bp-768 {
								padding: 7px 20px;
								width: 100%;
                line-height: 1.42857143;
                white-space: nowrap;
							}
							@include bp-992 {
								padding: 7px 30px;
							}
						}
					}
				}
			}
		}
	}
}
#footer {
	overflow: hidden;
	//float: left;
	width: 100%;
	position: relative;
	padding: 0 0 15px 0;
	background: #333538;
	margin: 15px 0 0 0;
	font-family: 'PT Sans', sans-serif;
	height: 350px;
	position: absolute;
	bottom: 0;
	@include bp-768  {
		padding: 45px 0 20px 0;
		margin: 30px 0 0 0;
		height: 270px;
	}
	@include bp-992  {
		height: 275px;
	}
	@include bp-1200  {
		padding: 50px 0 25px 0;
		height: 295px;
	}
	h3 {
		width: 100%;
		font-weight: 700;
		font-size: 15px;
		color: #fff;
		margin-top: 30px;
		@include bp-768 {
			margin: 0;
			font-size: 16px;
		}
		@include bp-992 {
			font-size: 17px;
		}
		@include bp-1200 {
			font-size: 17px;
		}
	}
	ul {
		width: 100%;
		margin-top: 10px;
		margin: 0;
		padding: 0;
		@include bp-768 {
			margin-top: 15px;
		}
		@include bp-1200 {
			margin-top: 15px;
		}
		li {
			width: 100%;
			margin-top: 2px;
			margin: 0;
			padding: 0;
			@include bp-768 {
				margin-top: 3px;
			}
			a {
				display: inline;
				color: #878fa2;
				font-size: 13px;
				font-family: 'PT Sans', sans-serif;
				@include transition(color .3s);
				@include bp-768 {
					font-size: 14px;
				}
				@include bp-992 {
					font-size: 15px;
				}
				&:hover,
				&:active,
				&:focus {
					text-decoration: none;
					color: #fff;
				}
			}
		}
	}
	.copyright {
		color: #878fa2;
		margin-top: 30px;
		padding-top: 15px;
		border-top: 1px solid rgba(29,31,35,1);
		width: 100%;
		text-align: center;
		font-family: 'PT Sans', sans-serif;
		font-size: 12px;
		@include bp-768 {
			margin-top: 45px;
			padding-top: 20px;
			font-size: 13px;
		}
		@include bp-1200 {
			margin-top: 50px;
			padding-top: 25px;
		}
		a {
			color: #878fa2;
			cursor: pointer;
			@include transition(color .3s);
			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				color: #fff;
			}
		}
	}
}